<template>
  <c-flex mt="5" w="70%" justify="space-between">
    <div>
      <c-text
        style="position: fixed; margin-top: -30px"
        fontWeight="600"
        fontSize="20px"
        >Search Results</c-text
      >
      <c-stack
        style="position: fixed; margin-top: 10px"
        borderRadius="lg"
        h="400px"
        w="248px"
        py="6"
        px="3"
        spacing="4"
        bg="#DEE8FD"
      >
        <c-text fontSize="lg" fontWeight="600" ml="2">Filter</c-text>
        <c-form-control display="flex" align-items="center">
          <c-flex
            :bg="selectedFilter === 'all' ? 'white' : 'transparent'"
            p="3"
            borderRadius="md"
            align="center"
            h="50px"
            w="100%"
            @click="updateFilters('all')"
          >
            <img src="@/assets/icons/search/Cards.svg#all_items" />
            <c-text fontWeight="500" ml="2">All</c-text>
          </c-flex>
        </c-form-control>
        <!--      <c-form-control display="flex" align-items="center">-->
        <!--        <c-flex-->
        <!--          bg="#83A5E955"-->
        <!--          pr="5px"-->
        <!--          borderRadius="md"-->
        <!--          align="center"-->
        <!--          h="50px"-->
        <!--          w="100%"-->
        <!--          justify="space-between"-->
        <!--        >-->
        <!--          <c-text fontWeight="500" ml="2">Posts</c-text>-->
        <!--          <c-checkbox-->
        <!--            variant-color="vc-orange"-->
        <!--            size="lg"-->
        <!--            v-model="filters.posts"-->
        <!--            :is-disabled="filters.all"-->
        <!--            @change="updateFilters"-->
        <!--          />-->
        <!--        </c-flex>-->
        <!--      </c-form-control>-->
        <!--      <c-form-control display="flex" align-items="center">-->
        <!--        <c-flex-->
        <!--          bg="#83A5E955"-->
        <!--          pr="5px"-->
        <!--          borderRadius="md"-->
        <!--          align="center"-->
        <!--          h="50px"-->
        <!--          w="100%"-->
        <!--          justify="space-between"-->
        <!--        >-->
        <!--          <c-text fontWeight="500" ml="2">Events</c-text>-->
        <!--          <c-checkbox-->
        <!--            variant-color="vc-orange"-->
        <!--            size="lg"-->
        <!--            v-model="filters.events"-->
        <!--            :is-disabled="filters.all"-->
        <!--            @change="updateFilters"-->
        <!--          />-->
        <!--        </c-flex>-->
        <!--      </c-form-control>-->
        <c-form-control display="flex" align-items="center">
          <c-flex
            :bg="selectedFilter === 'people' ? 'white' : 'transparent'"
            p="3"
            borderRadius="md"
            align="center"
            h="50px"
            w="100%"
            @click="updateFilters('people')"
          >
            <img src="@/assets/icons/search/Users.svg#people" />
            <c-text fontWeight="500" ml="2">People</c-text>
          </c-flex>
        </c-form-control>
        <c-form-control display="flex" align-items="center">
          <c-flex
            :bg="selectedFilter === 'businesses' ? 'white' : 'transparent'"
            p="3"
            borderRadius="md"
            align="center"
            h="50px"
            w="100%"
            @click="updateFilters('businesses')"
          >
            <img src="@/assets/icons/search/Buildings.svg#businesses" />
            <c-text fontWeight="500" ml="2">Businesses</c-text>
          </c-flex>
        </c-form-control>
        <c-form-control display="flex" align-items="center">
          <c-flex
            :bg="selectedFilter === 'channel' ? 'white' : 'channel'"
            p="3"
            borderRadius="md"
            align="center"
            h="50px"
            w="100%"
            @click="updateFilters('channel')"
          >
            <img src="@/assets/icons/search/HashStraight.svg#channel" />
            <c-text fontWeight="500" ml="2">Channels</c-text>
          </c-flex>
        </c-form-control>
      </c-stack>
    </div>
    <c-box ml="300px" mt="10px" w="100%" flex="2" px="4">
      <c-box
        mb="4"
        borderRadius="8px"
        v-if="
          (selectedFilter === 'businesses' || selectedFilter === 'all') &&
          companiesResults &&
          companiesResults.length
        "
        p="15px"
        bg="white"
      >
        <c-text fontSize="sm" fontWeight="600">Business</c-text>
        <div :key="item.id" v-for="item in companiesResults">
          <business-card :business="item" />
        </div>
        <!-- <c-flex justify="center">
          <c-button
              @click.native="moveToAllSearchResultsPage('business')"
              variant="solid"
              v-chakra="{
                backgroundColor: '#E0E8FB'
              }"
              width="100%"
              height="40px"
          >
            <c-text fontWeight="600">See all results</c-text>
          </c-button>
        </c-flex> -->
      </c-box>
      <c-box
        borderRadius="8px"
        mb="4"
        v-if="
          (selectedFilter === 'people' || selectedFilter === 'all') &&
          usersResults &&
          usersResults.length
        "
        p="15px"
        bg="white"
      >
        <c-text fontSize="sm" fontWeight="600">People</c-text>
        <div :key="item.id" v-for="item in usersResults">
          <people-card :person="item" />
        </div>
        <!-- <c-flex justify="center">
          <c-button
              @click.native="moveToAllSearchResultsPage('user')"
              variant="solid"
              height="40px"
              width="100%"
              v-chakra="{
                backgroundColor: '#E0E8FB'
              }"
          >
            <c-text fontWeight="600">See all results</c-text>
          </c-button>
        </c-flex> -->
      </c-box>
      <c-box
        borderRadius="8px"
        mb="4"
        v-if="
          (selectedFilter === 'channel' || selectedFilter === 'all') &&
          channelResults &&
          channelResults.length
        "
        p="15px"
        bg="white"
      >
        <c-text fontSize="sm" fontWeight="600">Channels</c-text>
        <div :key="item.id" v-for="item in channelResults">
          <channel-card :channel="item" />
        </div>
        <!-- <c-flex justify="center">
          <c-button
              @click.native="moveToAllSearchResultsPage('channel')"
              variant="solid"
              height="40px"
              width="100%"
              v-chakra="{
                backgroundColor: '#E0E8FB'
              }"
          >
            <c-text fontWeight="600">See all results</c-text>
          </c-button>
        </c-flex> -->
      </c-box>
      <!--      <c-box-->
      <!--        borderRadius="8px"-->
      <!--        mb="4"-->
      <!--        v-if="filters.events || filters.all"-->
      <!--        p="15px"-->
      <!--        bg="#E7EDF9"-->
      <!--      >-->
      <!--        <c-text fontSize="sm" fontWeight="600">Events</c-text>-->
      <!--        <div-->
      <!--          :key="event.id"-->
      <!--          v-for="event in result.filter((e) => e.type === 'event')"-->
      <!--        >-->
      <!--          <saved-event-card :event="event.item" />-->
      <!--        </div>-->
      <!--      </c-box>-->
      <!--      <c-box-->
      <!--        borderRadius="8px"-->
      <!--        mb="4"-->
      <!--        v-if="filters.posts || filters.all"-->
      <!--        p="15px"-->
      <!--        bg="#E7EDF9"-->
      <!--      >-->
      <!--        <c-text fontSize="sm" fontWeight="600">Posts</c-text>-->
      <!--        <div-->
      <!--          :key="post.id"-->
      <!--          v-for="post in result.filter((e) => e.type === 'post')"-->
      <!--        >-->
      <!--          <post-card :user="post.item" />-->
      <!--        </div>-->
      <!--      </c-box>-->
    </c-box>
  </c-flex>
</template>

<script>
import BusinessCard from '@/views/App/Explore/components/search/BusinessCard.vue';
import PeopleCard from '@/views/App/Explore/components/search/PeopleCard.vue';
import ChannelCard from '@/views/App/Explore/components/sidebar/ChannelCard.vue';
// import SavedEventCard from '@/views/App/Explore/components/saved/SavedEventCard.vue';
// import PostCard from '@/views/App/Explore/components/PostCard.vue';
import { mapGetters } from 'vuex';
import { searchChannel, searchCompany, searchUsers } from '@/services/insight';

export default {
  name: 'SearchLanding',
  components: {
    BusinessCard,
    PeopleCard,
    ChannelCard,
    // SavedEventCard,
    // PostCard,
  },
  computed: {
    ...mapGetters({
      searchParam: 'explore/getSearchParams',
      user: 'auth/getUser',
    }),
  },
  data() {
    return {
      resultsLimit: 4,
      resultsOffset: 0,
      resultsTotal: 0,
      companiesResults: null,
      usersResults: null,
      channelResults: null,
      selectedFilter: 'all',
    };
  },
  methods: {
    moveToAllSearchResultsPage(resultType) {
      this.$store.dispatch('explore/setSearchResutType', resultType);
      this.$router.push({ name: 'all-search-results' });
    },
    moveForward() {
      console.log('Movingg forward ***');
      if (this.resultsOffset < this.resultsTotal) {
        this.eventsOffset += this.resultsLimit;
        this.getCompaniesSearchResultsQuery();
        this.getUsersSearchResultsQuery();
      } else {
        this.getCompaniesSearchResultsQuery();
        this.getUsersSearchResultsQuery();
      }
    },
    moveBack() {
      if (this.resultsOffset > 0) {
        this.resultsOffset -= this.resultsLimit;
        this.getCompaniesSearchResultsQuery();
        this.getUsersSearchResultsQuery();
      } else {
        this.getCompaniesSearchResultsQuery();
        this.getUsersSearchResultsQuery();
      }
    },
    async getCompaniesSearchResultsQuery() {
      const response = await searchCompany({
        search_param: '%' + this.searchParam + '%',
        limit: this.resultsLimit,
        offset: this.resultsOffset,
      });
      this.companiesResults = response.data.company;
      this.loading = false;
    },

    async getUsersSearchResultsQuery() {
      const response = await searchUsers({
        search_param: '%' + this.searchParam + '%',
        offset: this.resultsOffset,
        limit: this.resultsLimit,
      });
      this.usersResults = response.data.user_search;
      this.loading = false;
    },

    async getChannelsSearchResultsQuery() {
      const response = await searchChannel({
        search_param: '%' + this.searchParam + '%',
        loggedInUserId: this.user.id,
        offset: this.resultsOffset,
        limit: this.resultsLimit,
      });
      this.channelResults = response.data.chat_channel;
      this.loading = false;
    },

    updateFilters(type) {
      this.selectedFilter = type;
    },
  },
  mounted() {
    this.result = this.$store.getters['search/getSearchResults'];
    this.getCompaniesSearchResultsQuery();
    this.getUsersSearchResultsQuery();
    this.getChannelsSearchResultsQuery();
  },
};
</script>

<style scoped></style>
