<template>
  <div
    class="py-3 px-5 my-5"
    style="width: 100%; background-color: #ffffff77; border-radius: 8px"
  >
    <div>
      <c-flex align="center" justify="space-between">
        <c-flex>
          <c-image
            style="border-radius: 5px"
            h="40px"
            w="40px"
            mr="3"
            alt="profile picture"
            :src="
              business.logo
                ? business.logo
                : require('@/assets/img/company.png')
            "
          />
          <c-flex justify="center" align="start" flexDirection="column">
            <c-text fontWeight="600" fontSize="sm">{{ business.name }}</c-text>
            <c-text
              fontWeight="500"
              style="color: lightslategray"
              fontSize="sm"
            >
              {{ business.city }} {{ business.country }}</c-text
            >
            <c-text fontWeight="400" style="color: #74777a" fontSize="xs">
              {{ business.company_followers_aggregate.aggregate.count }}
              {{
                business.company_followers_aggregate.aggregate.count === 1
                  ? 'follower'
                  : 'followers'
              }}</c-text
            >
          </c-flex>
        </c-flex>
        <div>
          <c-flex>
            <c-button
              borderRadius="lg"
              size="md"
              variant-color="vc-orange"
              variant="outline"
              mb="10px"
              >Message</c-button
            >
            <c-button
              borderRadius="lg"
              size="md"
              ml="5"
              variant-color="vc-orange"
              >Follow</c-button
            >
          </c-flex>
        </div>
      </c-flex>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BusinessCard',
  props: ['business'],
  mounted() {},
};
</script>

<style scoped></style>
